// LoginForm.js

import React, { useState, useContext } from 'react';
import axios from 'axios';
import './LoginFormStyles.css';
import { LanguageContext } from './LanguageProvider';
import translations from './translations.json';
import { useNavigate } from 'react-router-dom';

function LoginForm() {
    const [mobileNumber, setMobileNumber] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { selectedLanguage } = useContext(LanguageContext);
    const navigate = useNavigate();

    const translateText = (textKey) => {
        // Retrieve the translation for the selected language from the translations JSON
        const languageTranslations = translations[selectedLanguage];
        if (languageTranslations && languageTranslations[textKey]) {
            return languageTranslations[textKey];
        }
        // Return the text key if no translation is found
        return textKey;
    };

    const handleMobileNumberChange = (event) => {
        setMobileNumber(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!mobileNumber) {
            setError('Please enter the mobile number');
            return;
        } else {
            setError('');
        }

        if (!password) {
            setError('Please enter the password');
            return;
        } else {
            setError('');
        }

        // Validate mobile number format
        const mobileNumberPattern = /^\d{10}$/;
        if (!mobileNumberPattern.test(mobileNumber)) {
            setError('Please enter a valid 10-digit mobile number');
            return;
        } else {
            setError('');
        }

        try {
            const response = await axios.post(`${apiUrl}/api/v1/login`, {
                mobileNumber,
                password,
            });

            // Set isAuthenticated flag in local storage
            localStorage.setItem('isAuthenticated', 'true');

            // Redirect to dashboard
            navigate('/dashboard');
            setMobileNumber('');
            setPassword('');
            // Perform any necessary actions on successful login
        } catch (error) {
            console.error('Error:', error.response.data.message);
            setError(error.response.data.message);
        }
    };

    return (
        <div className="form-container">
            {error && <p>{error}</p>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="mobileNumber">Mobile Number:</label>
                    <input
                        type="tel"
                        id="mobileNumber"
                        value={mobileNumber}
                        onChange={handleMobileNumberChange}
                    />
                </div>
                <div>
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                </div>
                <button type="submit">Login</button>
            </form>
        </div>
    );
}

export default LoginForm;
