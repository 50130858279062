import "./FooterStyles.css"
const Footer = () => {
    return (
        <div>
            <footer className="footer">
                <p className="footer-left">TourBus &copy; 2023. All rights reserved.</p>
                <p className="footer-right">
                    <a href="/privacy-policy">Privacy Policy</a>
                </p>
            </footer>
        </div>
    )
}

export default Footer