import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import ServiceImg from "../assets/bus.jpg"
import Footer from "../components/Footer";
import Bus from "../components/Bus"
function Service() {
    return (
        <>
            <Navbar />
            <Hero
                cName="hero-mid"
                heroImg={ServiceImg}
                title="Service"
                btnClass="hide"
            />
            <Bus />
            <Footer />
        </>
    );
}

export default Service;