// Dashboard.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './DashboardStyles.css';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { format, parseISO } from 'date-fns';
import logoImg from "../assets/logo.png"

function Dashboard() {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const perPage = 5; // Number of items per page
    const apiUrl = process.env.REACT_APP_API_URL;

    // Fetch data from API
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/v1/bookinglist`); // Replace with your API endpoint
            setData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleLogout = () => {
        // Clear isAuthenticated flag from local storage
        localStorage.removeItem('isAuthenticated');

        // Redirect to home page or login page
        navigate('/');
    };

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    const offset = currentPage * perPage;
    const pageCount = Math.ceil(data.length / perPage);
    const currentPageData = data.slice(offset, offset + perPage);

    // Check isAuthenticated flag from local storage
    const isAuthenticated = localStorage.getItem('isAuthenticated');

    // Redirect to login if not authenticated
    if (!isAuthenticated) {
        navigate('/login');
        return null;
    }

    return (
        <div className="container">
            <a href="/"> <img className="header-logo" alt="img" src={logoImg} /></a>
            <button className="logout-button" onClick={handleLogout}>
                Logout
            </button>
            <table>
                {/* Table contents */}
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Mobile Number</th>
                        <th>Passengers</th>
                        <th>Pickup Location</th>
                        <th>Pickup Address</th>
                        <th>Destination Location</th>
                        <th>Destination Address</th>
                        <th>Trip Mode</th>
                        <th>Bus Type</th>
                        {/* <th>Bus Model</th> */}
                        <th>Journey Date</th>
                        <th>Return Date</th>
                    </tr>
                </thead>
                <tbody>
                    {currentPageData.map((item) => (
                        <tr key={item._id}>
                            <td>{item.name}</td>
                            <td>{item.mobileNumber}</td>
                            <td>{item.passengers}</td>
                            <td>{item.pickupLocation}</td>
                            <td>{item.pickupAddress}</td>
                            <td>{item.destinationLocation}</td>
                            <td>{item.destinationAddress}</td>
                            <td>{item.tripMode}</td>
                            <td>{item.busType}</td>
                            {/* <td>{item.busModel}</td> */}
                            <td>{format(parseISO(item.journeyDate), 'dd-MM-yyyy')}</td>
                            <td>{item.returnDate ? format(parseISO(item.returnDate), 'dd-MM-yyyy') : 'null'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <ReactPaginate
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={'pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                activeClassName={'active'}
            />
        </div>
    );
}

export default Dashboard;