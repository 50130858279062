import React, { useState, useContext } from 'react';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import PlacesAutocomplete from 'react-places-autocomplete';
import SweetAlert from 'react-bootstrap-sweetalert';
import './BookNowStyles.css';
import HomeImg from "../assets/bus.jpg"
import { LanguageContext } from './LanguageProvider';
import translations from './translations.json';
const BookNow = () => {
    const [name, setName] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [passengers, setPassengers] = useState('');
    const [pickupLocation, setPickupLocation] = useState('');
    const [pickupAddress, setPickupAddress] = useState('');
    const [destinationLocation, setDestinationLocation] = useState('');
    const [destinationAddress, setDestinationAddress] = useState('');
    const [tripMode, setTripMode] = useState('one-way');
    const [busType, setBusType] = useState('AC');
    // const [busModel, setBusModel] = useState('Premium');
    const [journeyDate, setJourneyDate] = useState(null);
    const [returnDate, setReturnDate] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [title, setTitle] = useState('');
    const [type, setType] = useState('');
    const [apiResponse, setApiResponse] = useState('');
    const { selectedLanguage } = useContext(LanguageContext);
    const [nameError, setNameError] = useState('');
    const [mobileNumberError, setMobileNumberError] = useState('');
    const [passengersError, setPassengersError] = useState('');
    const [pickupLocationError, setpickupLocationError] = useState('');
    const [pickupAddressError, setpickupAddressError] = useState('');
    const [destinationLocationError, setdestinationLocationError] = useState('');
    const [destinationAddressError, setdestinationAddressError] = useState('');



    const translateText = (textKey) => {
        // Retrieve the translation for the selected language from the translations JSON
        const languageTranslations = translations[selectedLanguage];
        if (languageTranslations && languageTranslations[textKey]) {
            return languageTranslations[textKey];
        }
        // Return the text key if no translation is found
        return textKey;
    };

    const handleAlertConfirm = () => {
        setShowAlert(false);
    };
    const validateForm = () => {
        let isValid = true;

        if (!name) {
            // Name validation
            isValid = false;
            setNameError(translateText("instruction-21"));
        }
        else {
            setNameError('');
        }

        if (!mobileNumber.trim()) {
            // Mobile number validation
            isValid = false;
            setMobileNumberError(translateText("instruction-22"));
        } else if (!/^\d{10}$/.test(mobileNumber)) {
            // Mobile number format validation
            isValid = false;
            setMobileNumberError(translateText("instruction-23"));
        } else {
            setMobileNumberError('');
        }

        if (!passengers) {
            // Name validation
            isValid = false;
            setPassengersError(translateText("instruction-24"));
        }
        else {
            setPassengersError('');
        }

        if (!pickupLocation) {
            // Name validation
            isValid = false;
            setpickupLocationError(translateText("instruction-25"));
        }
        else {
            setpickupLocationError('');
        }

        if (!pickupAddress) {
            // Name validation
            isValid = false;
            setpickupAddressError(translateText("instruction-26"));
        }
        else {
            setpickupAddressError('');
        }

        if (!destinationLocation) {
            // Name validation
            isValid = false;
            setdestinationLocationError(translateText('instruction-27'));
        }
        else {
            setdestinationLocationError('');
        }

        if (!destinationAddress) {
            // Name validation
            isValid = false;
            setdestinationAddressError(translateText("instruction-28"));
        }
        else {
            setdestinationAddressError('');
        }

        return isValid;
    };

    const apiUrl = process.env.REACT_APP_API_URL;
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        // Prepare the data to send to the API
        const formData = {
            name,
            mobileNumber,
            passengers,
            pickupLocation,
            pickupAddress,
            destinationLocation,
            destinationAddress,
            tripMode,
            busType,
            // busModel,
            journeyDate,
            returnDate,
        };
        // Make the API call using axios
        axios
            .post(`${apiUrl}/api/v1/bookings`, formData)
            .then((response) => {
                // Reset form fields
                setName('');
                setMobileNumber('');
                setPassengers('');
                setPickupLocation('');
                setPickupAddress('');
                setDestinationLocation('');
                setDestinationAddress('');
                setTripMode('one-way');
                setBusType('AC');
                // setBusModel('Premium');
                setJourneyDate(null);
                setReturnDate(null);
                // alert(response.data.message);
                setApiResponse(response.data.message);
                setShowAlert(true);
                setTitle("Thank You!")
                setType("success")
            })
            .catch((error) => {
                // Handle the API call error
                setApiResponse('An error occurred. Please try again later.');
                setShowAlert(true);
                setTitle("OPPS Error!")
                setType("info")

            });
    };

    return (
        <div className="hero-mid-book">
            <div>
                {showAlert && (
                    <SweetAlert
                        show={showAlert}
                        title={title}
                        type={type}
                        onConfirm={handleAlertConfirm}
                        customClass={{
                            confirmButton: 'custom-ok-button-class',
                        }}
                    >
                        {apiResponse}
                    </SweetAlert>
                )}
            </div>
            <img alt="HerpImg" src={HomeImg} />
            <div className="form-container-book">
                <h1>{translateText('instruction-1')}</h1>
                <form onSubmit={handleSubmit}>
                    <div className="form-row">
                        <label className="form-label">
                            {translateText('instruction-2')}:
                            <select className="form-input" value={tripMode} onChange={(e) => setTripMode(e.target.value)}>
                                <option value="one-way">{translateText('instruction-15')}</option>
                                <option value="round-trip">{translateText('instruction-16')}</option>
                            </select>
                        </label>
                        <label className="form-label">
                            {translateText('instruction-3')}:
                            <select className="form-input" value={busType} onChange={(e) => setBusType(e.target.value)}>
                                <option value="AC">{translateText("instruction-17")}</option>
                                <option value="Non AC">{translateText("instruction-18")}</option>
                            </select>
                        </label>
                        {/* <label className="form-label">
                            {translateText('instruction-4')}:
                            <select className="form-input" value={busModel} onChange={(e) => setBusModel(e.target.value)}>
                                <option value="Premium">{translateText("instruction-19")}</option>
                                <option value="Luxury">{translateText("instruction-20")}</option>
                            </select>
                        </label> */}
                    </div>
                    <div className="form-row">
                        <label className="form-label">
                            {translateText('instruction-5')}:
                            <DatePicker
                                className="form-input"
                                selected={journeyDate}
                                onChange={(date) => setJourneyDate(date)}
                                dateFormat="dd/MM/yyyy"
                                placeholderText={translateText('instruction-5')}
                                minDate={new Date()}
                                isClearable
                                required
                            />
                        </label>
                        {tripMode === 'round-trip' && (
                            <label className="form-label">
                                {translateText('instruction-6')}:
                                <DatePicker
                                    className="form-input"
                                    selected={returnDate}
                                    onChange={(date) => setReturnDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText={translateText('instruction-6')}
                                    minDate={journeyDate}
                                    isClearable
                                />
                            </label>
                        )}
                    </div>
                    <div className="form-row">
                        <label className="form-label">
                            {translateText('instruction-7')}:
                            <input
                                className="form-input"
                                type="text"
                                value={name}
                                placeholder={translateText('instruction-7')}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                            {nameError && <div className="error-message">{nameError}</div>}
                        </label>
                        <label className="form-label">
                            {translateText('instruction-8')}:
                            <input
                                className="form-input"
                                type="tel"
                                value={mobileNumber}
                                placeholder={translateText('instruction-8')}
                                onChange={(e) => setMobileNumber(e.target.value)}
                                required
                            />
                            {mobileNumberError && <div className="error-message">{mobileNumberError}</div>}
                        </label>
                        <label className="form-label">
                            {translateText('instruction-9')}:
                            <input
                                className="form-input"
                                type="number"
                                value={passengers}
                                placeholder={translateText('instruction-9')}
                                onChange={(e) => setPassengers(e.target.value)}
                                required
                            />
                            {setPassengersError && <div className="error-message">{passengersError}</div>}
                        </label>
                    </div>
                    <div className="form-row">
                        <label className="form-label">
                            {translateText('instruction-10')}:
                            <PlacesAutocomplete
                                value={pickupLocation}
                                onChange={(location) => setPickupLocation(location)}
                                onSelect={(location, address) => {
                                    setPickupLocation(location);
                                }}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <input className="form-input" {...getInputProps({ placeholder: translateText('instruction-10') })} />
                                        <div>
                                            {loading ? <div>Loading...</div> : null}
                                            {suggestions.map((suggestion) => {
                                                const style = {
                                                    backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                                                };
                                                return (
                                                    <div {...getSuggestionItemProps(suggestion, { style })}>
                                                        {suggestion.description}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                            {setpickupLocationError && <div className="error-message">{pickupLocationError}</div>}
                        </label>
                        <label className="form-label">
                            {translateText('instruction-11')}:
                            <input
                                className="form-input"
                                type="text"
                                placeholder={translateText('instruction-11')}
                                value={pickupAddress}
                                onChange={(e) => setPickupAddress(e.target.value)}
                            />
                            {setpickupAddressError && <div className="error-message">{pickupAddressError}</div>}
                        </label>
                    </div>
                    <div className="form-row">
                        <label className="form-label">
                            {translateText('instruction-12')}:
                            <PlacesAutocomplete
                                value={destinationLocation}
                                onChange={(location) => setDestinationLocation(location)}
                                onSelect={(location, address) => {
                                    setDestinationLocation(location);
                                }}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <input className="form-input" {...getInputProps({ placeholder: translateText('instruction-12') })} />
                                        <div>
                                            {loading ? <div>Loading...</div> : null}
                                            {suggestions.map((suggestion) => {
                                                const style = {
                                                    backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                                                };
                                                return (
                                                    <div {...getSuggestionItemProps(suggestion, { style })}>
                                                        {suggestion.description}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                            {setdestinationLocationError && <div className="error-message">{destinationLocationError}</div>}
                        </label>
                        <label className="form-label">
                            {translateText('instruction-13')}:
                            <input
                                className="form-input"
                                type="text"
                                value={destinationAddress}
                                placeholder={translateText('instruction-13')}
                                onChange={(e) => setDestinationAddress(e.target.value)}
                            />
                            {setdestinationAddressError && <div className="error-message">{destinationAddressError}</div>}
                        </label>
                    </div>
                    <button className="submit-button" type="submit">
                        {translateText('instruction-14')}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default BookNow;
