import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Home from './routes/Home';
import About from './routes/About';
import Contact from './routes/Contact';
import Service from './routes/Service';
import Login from './routes/Login';
import BookNow from './routes/Book';
import PrivacyPolicy from './routes/Privacy';
import Dashboard from './routes/Dashboard';

const App = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check if the user is authenticated in local storage on app load
    const storedAuthStatus = localStorage.getItem('isAuthenticated');
    if (storedAuthStatus === 'true') {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const handleLogin = () => {
    // Perform authentication logic
    // ...

    // Set isAuthenticated to true and store it in local storage
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true');

    // Navigate to the dashboard or desired route
    navigate('/dashboard');
  };

  const handleLogout = () => {
    // Perform logout logic, clear authentication token or session, etc.

    // Set isAuthenticated to false and remove it from local storage
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');

    // Navigate to the home page or desired route
    navigate('/');
  };

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route
          path="/login"
          element={<Login isAuthenticated={isAuthenticated} onLogin={handleLogin} />}
        />
        <Route path="/contact" element={<Contact />} />
        <Route path="/book-now" element={<BookNow />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/dashboard"
          element={<Dashboard isAuthenticated={isAuthenticated} onLogout={handleLogout} />}
        />
      </Routes>
    </div>
  );
};

export default App;
