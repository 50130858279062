import { LanguageProvider } from '../components/LanguageProvider';
import Navbar from "../components/Navbar";
import Privacy from "../components/PrivacyPolicy";
import Footer from "../components/Footer";

function PrivacyPolicy() {
    return (
        <>
            <LanguageProvider>
                <div>
                    <Navbar />
                    <Privacy />
                    <Footer />
                </div>
            </LanguageProvider >

        </>
    )
}

export default PrivacyPolicy;