import React, { createContext, useState } from 'react';


export const LanguageProvider = ({ children }) => {
    const [selectedLanguage, setSelectedLanguage] = useState('en');

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
    };

    const languageContextValue = {
        selectedLanguage,
        handleLanguageChange,
    };

    return (
        <LanguageContext.Provider value={languageContextValue}>
            {children}
        </LanguageContext.Provider>
    );
};

export const LanguageContext = createContext();
