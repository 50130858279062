import React, { useContext } from 'react';
import "./BusStyles.css";
import { LanguageContext } from './LanguageProvider';
import translations from './translations.json';
import BusData from "./BusData";
import tour1 from "../assets/tour1.jpg"
import tour2 from "../assets/tour2.jpg"
import tour3 from "../assets/tour3.jpeg"
import tour4 from "../assets/tour4.jpeg"
import tour5 from "../assets/tour5.jpeg"
import tour6 from "../assets/tour6.jpeg"

function Bus() {
    const { selectedLanguage } = useContext(LanguageContext);

    const translateText = (textKey) => {
        // Retrieve the translation for the selected language from the translations JSON
        const languageTranslations = translations[selectedLanguage];
        if (languageTranslations && languageTranslations[textKey]) {
            return languageTranslations[textKey];
        }
        // Return the text key if no translation is found
        return textKey;
    };
    return (
        <div className="trip">
            <h1>{translateText("instruction-29")}</h1>
            {/* <p>You can discover unique buses here</p> */}
            <div className="tripcard">
                <BusData
                    image={tour1}
                    heading={translateText("instruction-30")}
                // text="Proin porttitor in nunc vel condimentum. Nam consequat neque sit amet mauris mollis, a imperdiet purus tempus. In eu tincidunt dui."
                />
                <BusData
                    image={tour2}
                    heading={translateText("instruction-31")}
                // text="Proin porttitor in nunc vel condimentum. Nam consequat neque sit amet mauris mollis, a imperdiet purus tempus. In eu tincidunt dui."
                />
                <BusData
                    image={tour3}
                    heading={translateText("instruction-32")}
                // text="Proin porttitor in nunc vel condimentum. Nam consequat neque sit amet mauris mollis, a imperdiet purus tempus. In eu tincidunt dui."
                />
            </div>
            <div className="tripcard">
                <BusData
                    image={tour4}
                    heading={translateText("instruction-33")}
                // text="Proin porttitor in nunc vel condimentum. Nam consequat neque sit amet mauris mollis, a imperdiet purus tempus. In eu tincidunt dui."
                />
                <BusData
                    image={tour5}
                    heading={translateText("instruction-34")}
                // text="Proin porttitor in nunc vel condimentum. Nam consequat neque sit amet mauris mollis, a imperdiet purus tempus. In eu tincidunt dui."
                />
                <BusData
                    image={tour6}
                    heading={translateText("instruction-35")}
                // text="Proin porttitor in nunc vel condimentum. Nam consequat neque sit amet mauris mollis, a imperdiet purus tempus. In eu tincidunt dui."
                />
            </div>
        </div>
    )
}

export default Bus;