import React, { useContext } from 'react';
import "./AboutUsStyles.css"
import { LanguageContext } from './LanguageProvider';
import translations from './translations.json';

const AboutUs = () => {
    const { selectedLanguage } = useContext(LanguageContext);

    const translateText = (textKey) => {
        // Retrieve the translation for the selected language from the translations JSON
        const languageTranslations = translations[selectedLanguage];
        if (languageTranslations && languageTranslations[textKey]) {
            return languageTranslations[textKey];
        }
        // Return the text key if no translation is found
        return textKey;
    };
    return (
        <div className="about-cointainer">
            <h1>Our Address</h1>
            <p></p>
        </div>
    )
}

export default AboutUs