import { LanguageProvider } from '../components/LanguageProvider';
import Navbar from "../components/Navbar";
// import Destination from "../components/Destination";
import Footer from "../components/Footer";
import Bus from "../components/Bus";
import BookNow from "../components/BookNow";


function Home() {
    return (
        <>
            <LanguageProvider>
                <div>
                    <Navbar />
                    <BookNow />
                    {/* <Destination /> */}
                    <Bus />
                    <Footer />
                </div>
            </LanguageProvider >

        </>

    );
}

export default Home;