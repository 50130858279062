import { LanguageProvider } from '../components/LanguageProvider';
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import AboutImg from "../assets/bus.jpg"
import Footer from "../components/Footer";
import AboutUs from "../components/AboutUs";
function About() {
    return (
        <>
            <LanguageProvider>
                <div>
                    <Navbar />
                    <Hero
                        cName="hero-mid"
                        heroImg={AboutImg}
                        title="About Us"
                        btnClass="hide"
                    />
                    <AboutUs />
                    <Footer />
                </div>
            </LanguageProvider >
        </>
    );
}

export default About;