import React from 'react';
import './PrivacyPolicyStyles.css';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-container">
            <h1><b>Privacy Policy</b></h1>
            <p><b>Introduction</b><br></br> This privacy policy describes how we collect, use, and share your personal information when you book a bus trip through our website or app.</p>
            <p><b>What personal information do we collect?</b><br></br> When you book a bus trip, we collect the following personal information from you</p>
            <ul>
                <li>Your name</li>
                <li>Your phone number</li>
                <li>Your travel information (such as your destination, departure date, and return date)</li>
            </ul>
            <p><b>How do we use your personal information?</b><br></br> We use your personal information to</p>
            <ul>
                <li>Process your booking</li>
                <li>Send you confirmation and travel information</li>
                <li>Contact you if there are any changes to your booking</li>
                <li>Improve our website and app</li>
                <li>Target our marketing</li>
            </ul>
            <p><b>Who do we share your personal information with?</b><br></br> We may share your personal information with the following third parties:</p>
            <ul>
                <li>Our payment processor</li>
                <li>Our bus operators</li>
                <li>Our marketing partners</li>
            </ul>
            <p><b>How do we protect your personal information?</b><br></br> We take the security of your personal information very seriously. We use a variety of security measures to protect your personal information, including:</p>
            <ul>
                <li>Encryption</li>
                <li>Firewalls</li>
                <li>Data access restrictions</li>
            </ul>
            <p><b>Your rights</b><br></br> You have the following rights with respect to your personal information:</p>
            <ul>
                <li>The right to access your personal information</li>
                <li>The right to correct your personal information</li>
                <li>The right to delete your personal information</li>
            </ul>
            <p><b>How to contact us?</b><br></br> If you have any questions about this privacy policy, write to us from the Contact us page on our website.</p>
            <p>Changes to this privacy policy: We may update this privacy policy from time to time. The most recent version of the privacy policy will always be available on our website.</p>
            <p><b>In addition to the above, we would like to specifically note that we collect and use your name, phone number, and travel information for the following purposes</b><br></br></p>
            <ul>
                <li>To contact you in case of an emergency or other unexpected event that may affect your travel plans.</li>
                <li>To provide you with customer support if you have any questions or concerns about your booking.</li>
                <li>To improve our customer experience by understanding how you use our website and app.</li>
            </ul>
            <p>We will not sell or share your personal information with any third parties for their own marketing purposes.</p>
        </div>
    );
};

export default PrivacyPolicy;
