import React, { useState, useContext } from 'react';
import axios from 'axios';
import "./ContactFormStyles.css";
import { LanguageContext } from './LanguageProvider';
import translations from './translations.json';

function ContactForm() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');
    const { selectedLanguage } = useContext(LanguageContext);
    const apiUrl = process.env.REACT_APP_API_URL;

    const translateText = (textKey) => {
        // Retrieve the translation for the selected language from the translations JSON
        const languageTranslations = translations[selectedLanguage];
        if (languageTranslations && languageTranslations[textKey]) {
            return languageTranslations[textKey];
        }
        // Return the text key if no translation is found
        return textKey;
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Perform form validation
        if (!name) {
            setError(translateText("instruction-21"));
            return;
        }
        if (!email) {
            setError(translateText("instruction-40"));
            return;
        } else {
            setError('');
        }
        if (!validateEmail(email)) {
            setError(translateText("instruction-41"));
            return;
        } else {
            setError('');
        }
        if (!message) {
            setError(translateText("instruction-42"));
            return;
        } else {
            setError('');
        }

        // Prepare the data to send to the API
        const formData = {
            name,
            email,
            message
        };

        setLoading(true);

        try {
            // Make the API call using axios
            const response = await axios.post(`${apiUrl}/api/v1/contact-us`, formData);
            console.log(response);
            setSuccess(true);
            // Reset form fields
            setName('');
            setEmail('');
            setMessage('');
        } catch (error) {
            setError('An error occurred. Please try again later.');
        }

        setLoading(false);
    };

    return (
        <div className="form-container">
            {/* <h1>Send a message to us!</h1> */}
            <form onSubmit={handleSubmit}>
                <input
                    placeholder={translateText("instruction-7")}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <input
                    placeholder={translateText("instruction-36")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <textarea
                    placeholder={translateText("instruction-37")}
                    rows="4"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                {error && <p className="error">{error}</p>}
                {success && <p className="success">{translateText("instruction-43")}</p>}
                <button type="submit" disabled={loading}>
                    {loading ? translateText("instruction-38") : translateText("instruction-39")}
                </button>
            </form>
        </div>
    );
}

export default ContactForm;
