import Footer from "../components/Footer";
import Dashboard from "../components/Dashboard";
function Dashbord() {
    return (
        <>
            <div>
                <Dashboard />
                <Footer />
            </div>

        </>

    );
}

export default Dashbord;