import React, { useState, useContext } from 'react';
import { Link, /*useNavigate*/ } from 'react-router-dom';
import './NavbarStyles.css';
import { MenuItems } from './MenuItems';
import logoImg from "../assets/logo.png"
import translations from './translations.json';
import { LanguageContext } from './LanguageProvider';


const Navbar = () => {
    const [clicked, setClicked] = useState(false);
    // const navigate = useNavigate();

    const handleClick = () => {
        setClicked(!clicked);
    };
    const { selectedLanguage, handleLanguageChange } = useContext(LanguageContext);

    const handleChangeLanguage = () => {
        const newLanguage = selectedLanguage === 'en' ? 'ta' : 'en';
        handleLanguageChange(newLanguage);
    };
    const translateText = (textKey) => {
        // Retrieve the translation for the selected language from the translations JSON
        const languageTranslations = translations[selectedLanguage];
        if (languageTranslations && languageTranslations[textKey]) {
            return languageTranslations[textKey];
        }
        // Return the text key if no translation is found
        return textKey;
    };
    // const handleBookNowClick = () => {
    //     navigate('/book-now');
    // };

    return (
        <div className="Navbar-items-header">
            <div className="header">
                <a href="/"> <img className="header-logo" alt="img" src={logoImg} /></a>
                {/* <h1 className="header-logo-text">~ Since 1920 ~</h1> */}
                <nav className="NavbarItems">
                    <div className="menu-icons" onClick={handleClick}>
                        <i className={clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
                    </div>

                    <ul className={clicked ? 'nav-menu active' : 'nav-menu'}>
                        {MenuItems.map((item, index) => (
                            <li key={index}>
                                <Link className={item.cName} to={item.url}>
                                    <i className={item.icon}></i>
                                    {translateText(item.title)}
                                </Link>
                            </li>
                        ))}
                        <button className="book-now-btn" onClick={handleChangeLanguage}>
                            {selectedLanguage === 'en' ? 'தமிழ்' : 'English'}
                        </button>
                        {/* <button className="book-now-btn" onClick={handleBookNowClick}>
                    Book Now
                </button> */}
                    </ul>
                </nav>
            </div>

        </div>

    );
};

export default Navbar;