import { LanguageProvider } from '../components/LanguageProvider';
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import LoginImg from "../assets/bus.jpg"
import Footer from "../components/Footer";
import LoginPage from "../components/LoginForm";

function Login() {
    return (
        <>
            <LanguageProvider>
                <div>
                    <Navbar />
                    <Hero
                        cName="hero-mid"
                        heroImg={LoginImg}
                        title="Login"
                        btnClass="hide"
                    />
                    <LoginPage />
                    <Footer />
                </div>
            </LanguageProvider >

        </>
    )
}

export default Login;