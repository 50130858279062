export const MenuItems = [
    // {
    //     title: "Home",
    //     url: "/",
    //     cName: "nav-links",
    //     icon: "fa-solid fa-house-user"
    // },
    {
        title: "About Us",
        url: "/about",
        cName: "nav-links",
        icon: "fa-solid fa-circle-info"
    },
    // {
    //     title: "Service",
    //     url: "/service",
    //     cName: "nav-links",
    //     icon: "fa-solid fa-briefcase"
    // },
    {
        title: "Contact Us",
        url: "/contact",
        cName: "nav-links",
        icon: "fa-solid fa-phone"
    },
    {
        title: "Admin Login",
        url: "/login",
        cName: "nav-links",
        icon: "fa-solid fa-sign-in-alt"
    },
    // {
    //     title: "Book Now",
    //     url: "/book-now",
    //     cName: "nav-links-mobile",
    // },
]