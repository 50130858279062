import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import BookNowImg from "../assets/bus.jpg"
import Footer from "../components/Footer";
import BookNow from "../components/BookNow";

function Contact() {
    return (
        <>
            <Navbar />
            <Hero
                cName="hero-mid"
                heroImg={BookNowImg}
                title="Book your bus"
                btnClass="hide"
            />
            <BookNow />
            <Footer />
        </>
    )
}

export default Contact;